.rydercup,
.rydercup-quickbar {
  width: 185px;
  overflow: hidden;

  svg {
    width: 200px;
  }
}

.rydercup_title-top {
  color: #fff;
  font-size: 11px;
}

.rydercup_logo-top {
  margin: 5px auto;
  height: 40px;
  img {
    max-height: 40px;
  }
}

.rydercup-quickbar {
  img {
    display: block;
    max-height: 45px;
  }
}

.rydercup-quickbar {
  margin-left: auto;
  // margin-right: -10px;
}
