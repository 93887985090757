.scoring-squads {
  font-size: 1.4rem;
  @media (max-width: 710px) {
    font-size: 1rem;
  }
  .squad-eur {
    background: #003c82;
  }
  .squad-usa {
    background: #c81414;
  }
}
.table-head-lead {
  .team-eur {
    background: #003c82;
  }
  .team-usa {
    background: #c81414;
  }
}

.player-lead {
  svg {
    width: 25px;
    height: 25px;
    fill: #c81414;
  }

  // @media (max-width: 576px) {
  //   padding: 0 10px !important;
  //   display: flex;
  //   justify-content: center;
  //   .arrow-icon {
  //     display: none;
  //   }
  // }
}

// @media (max-width: 576px) {
//   .center-table-wrapper {
//     background: rgb(239, 239, 239);
//     table {
//       max-width: 50%;
//       margin: auto;
//     }
//   }
// }

div[data-match-id] {
  .round-name + .round-name {
    margin-top: 20px;
  }

  &:nth-child(odd) {
    background-color: #f5f5f5;
  }
  cursor: pointer;

  &:hover {
    .full-name {
      color: #c81414;
    }
  }
}

@media (max-width: 768px) {
  .points-section {
    .row {
      font-size: 80%;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media (max-width: 576px) {
  .points-section {
    svg {
      width: 15px;
      height: 15px;
    }
  }
}

@media (max-width: 991px) {
  .players-eur {
    order: 1;
  }
  .players-usa {
    order: 2;
  }

  .leader-points {
    order: 3;
    flex-basis: auto;
    // margin-top: 20px;
  }
}

.player-details-wrapper {
  // border-right: 1px solid var(--bs-gray-300);
  @media (min-width: 576px) {
    flex-direction: row;
    display: flex;
  }

  @media (min-width: 992px) {
    flex-direction: column;
  }
}

.player-info-wrapper {
  margin: 10px 0;

  line-height: 100%;
  img {
    max-width: 100px;
  }
  .country {
    color: #adb5bd;
    font-size: 80%;
  }
  + .player-info-wrapper {
    @media (min-width: 992px) {
      padding-top: 15px;
      border-top: 1px solid var(--bs-gray-300);
    }
  }
}

.player-image img {
  display: block;
  margin: auto;
}

.color-standing {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80%;
}

// -----------------

.color-usa {
  background: #c81414;
}

.color-eur {
  background: #003c82;
}

// -----------------
.leader-points-table {
  margin-bottom: 40px;
}

.points-table tbody tr > td {
  border-right: 1px solid #fff !important;
}

.table-points-wrapper {
  border: 1px solid #e7e7e7;
  font-size: 90%;
  overflow-x: auto;
  @media (max-width: 607px) {
    // overflow-x: auto;
  }
}

.points-leader {
  padding: 0.5rem 0.13rem !important;
  .flag-icon {
    width: 19px;
    height: 19px;
    margin: auto;
  }
}

.points-matches {
  font-size: 85%;
  > td:not(:first-child) {
    padding: 0.5rem 0 !important;
  }
}

.points-table {
  margin: 0 !important;
  caption-side: bottom;
  border-collapse: collapse;
  background: #fafeff;

  thead tr:first-child {
    background-color: var(--main-color-bg, #08285d);
    color: #fff;
  }

  td:first-child {
    text-align: left;
    width: auto;
    // padding-right: 0;
    font-weight: bold;
  }

  tr td:first-child,
  td:first-child {
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9;
  }

  > :not(:first-child) {
    border-top: none !important;
  }

  .dark-color td:first-child {
    background-color: var(--main-color-bg, #08285d);
  }
  .light-color td:first-child {
    background: #fafeff;
  }

  .team-eur {
    background: #003c82;
    color: #fff !important;
    &:empty {
      background: transparent !important;
    }
  }
  .team-usa {
    background: #c81414;
    color: #fff !important;
    &:empty {
      background: transparent !important;
    }
  }
}

.points-section-row {
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;

  @media (min-width: 992px) {
    align-items: center !important;
  }

  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0ms 400ms, opacity 400ms 0ms;
}

.expandable {
  // display: flex;
  // min-height: 0;
  // padding: 40px 0;
  // grid-template-columns: repeat(2, 1fr);
}

.show-players-points {
  color: #c81414;
  .arrow-icon {
    rotate: 180deg;
  }
  // background: #f2f2f2;

  // span {
  //   border: none;
  // }

  .player-info-wrapper {
    border-bottom: none;
  }
}

.active-row {
  // grid-template-rows: 1fr;
  // overflow: auto;
  // opacity: 1;
  // display: block !important;
  // transition: opacity 500ms ease-in;

  height: auto;
  opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}

.point-matches-item {
  font-size: 90%;
}

.leader-head {
  margin: 30px auto 10px;
  @media (max-width: 992px) {
    margin: 10px auto;
  }
}

.table-leaderboard-center {
  table-layout: fixed;
  margin: 0;
  border-color: transparent;

  td {
    padding: 0 !important;
  }

  .center-table {
    width: 40px;
    background: #f6f6f6;
    @media (max-width: 400px) {
      width: 20px;
    }
    text-align: center;
    vertical-align: middle;
    // &.match-number {
    //   color: #c81414;
    // }
  }
}

// .leaderboard-head {
//   font-size: 10px;
//   line-height: 100%;
// }

.scoring-logo {
  position: relative;
}

.logo-scoring {
  // border: 1px solid red;
  position: absolute;
  max-width: 120px;
  left: 0;
  right: 0;
  margin: auto;
  top: -50px;
}
